<template>
  <div class="header">
    <div class="popupWrapper" v-if="popup_menu_visible" @click.self="popup_menu_visible = false"></div>
    <div class="popupWrapper" v-if="popup_change_business" @click.self="popup_change_business = false">
      <div class="popup-window-small">
        <div class="header">
          <p>Switch Account</p>
          <button class="close" @click="popup_change_business = false"></button>
        </div>
        <div class="body">
          <v-select name="business" label="label" v-model="business_id" :options="business_list" :searchable="false"
            :clearable="false" placeholder="Select business" :reduce="(label) => label.code" style="width: 100%"
            @input="selectNewBusiness"></v-select>
        </div>
      </div>
    </div>

    <div class="headerLine"></div>
    <div class="nav">
      <div class="left-part">
        <div class="logo-wrapper">
          <img src="../assets/logo.png" class="logo" @click="goHome" />
          <label @click="goHome" class="logo-text cursor-pointer">Agenic</label>
        </div>
        
      </div>
      <!-- Burger menu -->
      <ul class="menu" v-if="auth">
        <li class="hide-desktop">
          <div class="profile-box">
            <div class="render_avatar">
              <p>{{ render_avatar }}</p>
            </div>
          </div>
        </li>
        <li class="hide-mobile">
          <b-dropdown right class="menu-dropdown" ref="dropdown_desktop">
            <template #button-content>
              <div class="profile">
                <p>
                  <TruncateText v-if="user.account_role === 2" :text="user.registration_name" :popover="false"
                    :length="18" />
                  <TruncateText v-else-if="user.account_role === 1 && user.current_business !== null
      " :popover="false" :text="business_name()" :length="18" />
                </p>
                <div class="render_avatar">
                  <p>{{ render_avatar }}</p>
                </div>
              </div>
            </template>
            <b-dropdown-item class="cursor-pointer" @click="$router.push({ name: editProfileRouteName })">
              <span class="ai icon-ai-profile icon-font"></span>
              Edit profile
            </b-dropdown-item>
            <b-dropdown-item class="cursor-pointer" @click="$router.push({ name: 'BusinessEditCurrentBusiness' })"
              v-if="user.account_role === 1 && user.current_business !== null">
              <span class="ai icon-ai-pencil icon-font"></span>
              Manage Account
            </b-dropdown-item>
            <b-dropdown-item class="cursor-pointer" @click="$router.push({ name: 'BusinessEntry' })"
              v-if="user.account_role === 1 && user.current_business !== null">
              <span class="ai icon-ai-tab icon-font"></span>
              Switch Account
            </b-dropdown-item>
            <b-dropdown-item class="cursor-pointer" @click="logOut">
              <span class="ai icon-ai-exit icon-font"></span>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <li class="hide-desktop">
          <b-dropdown right class="menu-dropdown" style="z-index: 10" ref="dropdown_mobile">
            <template #button-content>
              <div class="profile-box">
                <!--                <img :src="menu_burger_src" alt="menu" />-->
                <span class="ai icon-ai-menu burger_menu_mobile" :class="{ active: menu_burger_src }"></span>
              </div>
            </template>
            <b-dropdown-item class="cursor-pointer" @click="$router.push({ name: editProfileRouteName })">
              <span class="ai icon-ai-profile icon-font"></span>
              Edit profile
            </b-dropdown-item>
            <b-dropdown-item class="cursor-pointer" @click="$router.push({ name: 'BusinessEditCurrentBusiness' })"
              v-if="user.account_role === 1 && user.current_business !== null">
              <span class="ai icon-ai-pencil icon-font"></span>
              Manage Account
            </b-dropdown-item>
            <b-dropdown-item class="cursor-pointer" @click="$router.push({ name: 'BusinessEntry' })"
              v-if="user.account_role === 1 && user.current_business !== null">
              <span class="ai icon-ai-tab icon-font"></span>
              Switch Account
            </b-dropdown-item>
            <b-dropdown-item class="cursor-pointer" @click="logOut">
              <span class="ai icon-ai-exit icon-font"></span>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TruncateText from "./TruncateText";

export default {
  name: "Header",
  components: { TruncateText },
  computed: {
    ...mapState(["user", "business_list"]),
    render_avatar() {
      return this.user.registration_name.slice(0, 1).toUpperCase();
    },
    showMenuSource() {
      if (
        !["BusinessSourcingList", "BusinessCreateSourcing"].includes(
          this.$route.name
        ) &&
        this.menu_sourcing
      ) {
        return true;
      } else if (
        !["BusinessSourcingList", "BusinessCreateSourcing"].includes(
          this.$route.name
        ) &&
        !this.menu_sourcing
      ) {
        return false;
      }
      return true;
    },
    showMenuCampaign() {
      if (
        ![
          "CreatorCampaignsList",
          "CreatorDelivery",
          "BusinessCampaignInfo",
          "BusinessCampaignDelivery",
          "BusinessCampaignList",
        ].includes(this.$route.name) &&
        this.menu_campaign
      ) {
        return true;
      } else if (
        ![
          "CreatorCampaignsList",
          "CreatorDelivery",
          "BusinessCampaignInfo",
          "BusinessCampaignDelivery",
          "BusinessCampaignList",
        ].includes(this.$route.name) &&
        !this.menu_campaign
      ) {
        return false;
      }
      return true;
    },
    business_id: {
      set(newValue) {
        this.$store.commit("updateCurrentBusinessID", newValue);
      },
      get() {
        return this.user.current_business;
      },
    },
    editProfileRouteName() {
      return this.user.account_role === 1
        ? "BusinessEditProfile"
        : "CreatorEditProfile";
    },
    campaignRouteName() {
      return this.user.account_role === 1
        ? "BusinessCampaignList"
        : "CreatorCampaignsList";
    },
  },
  props: {
    auth: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menu_campaign: true,
      menu_sourcing: true,
      menu_burger_src: false,

      currently_open: false,
      business_options: [],
      popup_change_business: false,
      popup_menu_visible: false,
    };
  },
  created() { },
  mounted() {
    setTimeout(() => {
      this.menu_campaign = false;
      this.menu_sourcing = false;
    }, 1500);

    
    window.addEventListener("resize", this.resizeEventHandler);
    

    this.$root.$on("bv::dropdown::shown", () => {
      if (this.popup_menu_visible === true) {
        this.dont_close = true;
      } else {
        this.popup_menu_visible = true;
        this.menu_burger_src = true;

        if (window.innerWidth <= 800) {
          document.body.style["overflow-y"] = "hidden";
        }
      }
    });

    this.$root.$on("bv::dropdown::hide", () => {
      if (this.dont_close === true) {
        this.dont_close = false;
        this.menu_burger_src = false;
        if (window.innerWidth <= 800) {
          document.body.style["overflow-y"] = "auto";
        }
      } else {
        this.popup_menu_visible = false;
        this.menu_burger_src = false;
        if (window.innerWidth <= 800) {
          document.body.style["overflow-y"] = "auto";
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  methods: {
    ...mapActions(["getCurrentUser"]),
    resizeEventHandler() {
      if (this.$refs && this.$refs.dropdown_desktop) {
        this.$refs.dropdown_desktop.hide(true);
      }
      if (this.$refs && this.$refs.dropdown_mobile) {
        this.$refs.dropdown_mobile.hide(true);
      }
      
    },
    logOut() {
      this.popup_menu_visible = false;
      this.$router.push({ name: "Logout" });
    },
    business_name() {
      const business_name_object = this.business_list.filter(
        (item) => item.code === this.user.current_business
      );
      if (business_name_object.length) {
        return business_name_object[0].label;
      }
      return "";
    },
    selectNewBusiness() {
      this.axios
        .post(this.$getRoute("select_business", { id: this.business_id }))
        .then(async () => {
          await this.getCurrentUser();

          this.popup_change_business = false;
          if (this.$route.name === "BusinessCampaignList") {
            this.$eventBus.$emit("reloadBusinessCampaignList");
          } else {
            this.$router.push({ name: "BusinessChannelList" });
          }
        });
    },
    goHome() {
      if (this.$route.name != "Home") this.$router.push({ name: "Home" });
    },
    logout() {
      localStorage.removeItem("token");
      this.$store.commit("makeAuth", false);
      this.$store.commit("updateUser", {
        account_role: null,
        email: "",
        id: 0,
        registration_name: "",
      });
      setTimeout(() => {
        this.$router.push({ name: "Home" });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 5px;
  background: $color_background;
  z-index: 1000;
  width: 100%;

  @media screen and (max-width: 1201px) {
    width: 100%;
    padding: 0 5px;
  }

  .headerLine {
    display: block;
    height: 7px;
    border-radius: 20px;
    background: #c278d2;
    margin: 0 10px 15px;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 45px;
    padding: 0 30px;

    @media screen and (max-width: 1201px) {
      padding: 0 10px;
    }

    .left-part {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .logo-wrapper {
        display: flex;
        align-items: center;
        margin-right: 48px;

        span.icon-ai-logo {
          display: block;
          margin-right: 10px;
          font-size: 32px;
          cursor: pointer;
          color: $color_pink;
        }

        .logo-text {
          display: block;
          font-family: "Gilroy", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          color: #434343;
        }
      }

      .submenu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        height: 45px;
        padding: 0;
        margin: 0;
        list-style-type: none;

        @media screen and (max-width: 800px) {
          display: none;
        }

        li {
          height: 45px;
          line-height: 27px;
          font-size: 12px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0;
          margin-right: 20px;

          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .submenu-icon-font {
              color: #848282;
              font-size: 18px;

              &.active {
                color: #000000;
              }
            }

            .icon-menu {
              display: block;
              width: 18px;
              height: 18px;
              cursor: pointer;
              margin: 0;
            }

            .bicon-menu-text {
              font-family: "Gilroy", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              color: #434343;
              padding-left: 10px;

              &.active {
                font-family: "Gilroy", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 25px;
                color: #000000;
              }
            }
          }

          .submenu-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 150px;
            margin-right: 10px;

            img {
              display: block;
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 45px;
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        height: 45px;
        line-height: 27px;
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;

        .profile-box {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          margin-right: 5px;

          .render_avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background: $color_background;
            border: 1px solid $color_pink;
            box-sizing: border-box;

            p {
              font-family: "Gilroy", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              text-align: center;
              line-height: normal;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $color_pink;
              width: 30px;
              height: 30px;
            }
          }

          .burger_menu_mobile {
            color: #606061;
            font-size: 30px;

            &.active {
              color: #d9d9d9;
            }
          }

          .avatar {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
            cursor: pointer;
          }
        }

        hr.dropdown-divider {
          height: 1px;
          background: #dbdbdb;
          width: 90%;
          margin: 0 auto;
        }

        a.dropdown-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: red !important;

          .icon-menu {
            display: block;
            width: 18px;
            height: 18px;
            cursor: pointer;
            margin: 0;
          }

          .bicon-menu-text {
            font-family: "Gilroy", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #434343;
            padding-left: 10px;

            &.active {
              color: $color_pink;
            }
          }
        }

        .submenu-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 150px;
          margin-right: 10px;

          img {
            display: block;
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.hide-desktop {
  display: none !important;

  @media screen and (max-width: 800px) {
    display: block !important;
  }
}

.hide-mobile {
  display: block !important;

  @media screen and (max-width: 800px) {
    display: none !important;
  }
}
</style>