import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./store/const.js";

Vue.config.productionTip = false;
Vue.prototype.CONST = window.CONST;

// LOAD DATA FROM .ENV
window.CONST.API_SERVER = process.env.VUE_APP_API_URL;
window.CONST.FB_APP_NAME = process.env.VUE_APP_FB_APP_NAME;
window.CONST.FB_APP_ID = process.env.VUE_APP_FB_APP_ID;
window.CONST.FB_APP_SOCIAL_PROVIDER =
  process.env.VUE_APP_FB_APP_SOCIAL_PROVIDER;

// BOOTSTRAP VUE
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import TextareaAutosize from "vue-textarea-autosize";
Vue.use(TextareaAutosize);

// VUE SELECT
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

// VEE-VALIDATE 2.0
import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";
// -----------------
const dictionary = {
  en: {
    messages: {
      email: () => "Incorrect email",
      min: () => "must be at least 6 characters",
    },
  },
};
// Override and merge the dictionaries
Validator.localize(dictionary);
// -----------------
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
});

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);
import "vue-multiselect/dist/vue-multiselect.min.css";
import VueTagsInput from "@johmun/vue-tags-input";
Vue.component("vue-tags-input", VueTagsInput);

import { getRoute } from "./URLHelper";

Vue.prototype.$getRoute = getRoute;
window.$getRoute = getRoute;

import VueLodash from "vue-lodash";
import lodash from "lodash";
Vue.use(VueLodash, { name: "lodashjs", lodash: lodash });

import VueConfirmDialog from "vue-confirm-dialog";
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";
Vue.use(VModal);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
Vue.use(DatePicker);

// Notification
import VueNoty from "vuejs-noty";
Vue.use(VueNoty, {
  timeout: 5000,
  progressBar: true,
  layout: "bottomRight",
  killer: true,
});
import "vuejs-noty/dist/vuejs-noty.css";

// MomentJS
import VueMoment from "vue-moment";
Vue.use(VueMoment);

// PortalVue
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// AXIOS
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import vueCurrencyDirective from "vue-currency-directive";
Vue.directive("currency", vueCurrencyDirective);

axios.interceptors.request.use((config) => {
  config.baseURL = window.CONST.API_SERVER + "api";
  config.timeout = 600000;
  config.headers = {
    Authorization: `${localStorage.getItem("token") == null
      ? ""
      : "Token " + localStorage.getItem("token")
      }`,
    Accept: "application/json; charset=utf-8",
  };
  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response) {
      // check status code
      switch (+error.response.status) {
        case 401:
          // Unauthorized
          //window.$app.$router.push({ name: "Logout" });
          localStorage.removeItem("token");
          window.$app.$store.commit("makeAuth", false);
          window.$app.$store.commit("updateUser", {
            account_role: null,
            email: "",
            id: 0,
            registration_name: "",
          });
          window.$app.$noty.error("Unauthorized");
          window.location = "/";
          break;
        case 400:
          var url = error.response.config.url;
          if (["/social/facebook/account/"].includes(url)) break;
          var keys = Object.keys(error.response.data);
          keys.forEach((item) => {
            if (item === "non_field_errors") {
              window.$app.$noty.error(error.response.data[item][0]);
            } else {
              window.$app.$noty.error(
                "<strong>" + item + "</strong>: " + error.response.data[item][0]
              );
            }
          });
          break;
        case 403:
          window.location = "/logout";
          break;
        case 405:
          break;
        case 422:
          //$app.$noty.error(error.response.data.message);
          break;
        default:
          break;
      }
    } else {
      window.$app.$noty.error(error.name + " : " + error.message)
    }


    return Promise.reject(error);
  }
);

VeeValidate.Validator.extend("phoneNumber", {
  validate(value) {
    return /^\+?1?\d{8,15}$/.test(value);
  },
  getMessage(field) {
    return `${field} is not a valid phone number`;
  },
});

VeeValidate.Validator.extend("passwordRules", {
  validate(value) {
    // let result_1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{4,48}$/.test(
    //   value
    // );

    // return /(?=.*[0-9])(?=.*[!@#$%^&*\-_])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*\-_]{6,48}/g.test(
    //   value
    // );
    let result_1 = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,48}/g.test(
      value
    );
    let result_2 = false;

    let allowedChars = [];
    let index = 0;

    // numbers from 0 to 9
    index = 48;
    while (index <= 57) {
      allowedChars.push(index);
      index++;
    }
    // Uppercase A-Z
    index = 65;
    while (index <= 90) {
      allowedChars.push(index);
      index++;
    }
    // Lowercase a-z
    index = 97;
    while (index <= 122) {
      allowedChars.push(index);
      index++;
    }

    for (let i = 0; i < value.length; i++) {
      let check = value.charCodeAt(i);
      if (!allowedChars.includes(check)) {
        result_2 = true;
        break;
      }
    }

    return result_1 && result_2;

    // return /^\+?1?\d{8,15}$/.test(value);
  },
  getMessage() {
    return `Password strength: weak. At least 6 characters and contains number and symbol`;
  },
});

Vue.prototype.$eventBus = new Vue();

Vue.filter("truncate", function (text, length = 37, suffix = "...") {
  text = "" + text;
  if (text.length <= +length) {
    return text;
  } else {
    return text.substring(0, length).trim(" ") + suffix;
  }
});

// MAIN STYLE
import "./scss/main.scss";

window.$app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
